const asNumber = (input, negative) => {
    if(["", "0"].includes(input)) return "0"
    var output = [",","."].includes(input) ? "0.0":input.replace(/[,\\.]/, "|").replace(/[,\\.]/g, '').replace("|", ".")
    if(negative){
        output = output.match(output.includes(".") ? /\-?([0-9]*)*\.[0-9]{0,3}/:/\-([0-9]+)*/)[0]
    } else {
        output = output.match(output.includes(".") ? /([0-9]*)\.[0-9]{0,3}/:/([1-9][0-9]*)/)[0]
    }
    return output
}

const cap = str => str.toUpperCase()[0]+str.slice(1)

// Function that recursively find the value of an object path. It only works for objects and path arrays
const deepPath = (obj, path) => path.length > 1 ? deepPath(obj?.[path[0]], path.slice(1)):obj?.[path[0]]

const deepUpdate = (obj, path, newValue) => {return {...obj, [path[0]]: path.length > 1 ? deepUpdate(obj?.[path[0]], path.slice(1), newValue):newValue}}

const formatFIXtime = d => {
    let output = new Date([d.slice(0,4), d.slice(4,6), d.slice(6).replace("-", " ")].join("-"))
    // The 60*1000 factor converts minutes into milliseconds
    output.setTime(output.getTime()-output.getTimezoneOffset()*60*1000)
    return output
}

const shortTimeFormat = dateObject => `${dateObject.getDate()}/${dateObject.getMonth()+1}-${dateObject.toLocaleTimeString()}`

const shortDateFormat = dateObject => `${dateObject.getDate()}/${dateObject.getMonth()+1}-${dateObject.getFullYear()}`

const default_instrument_params = {price: 0, amount: 0, order_type: "limit", buy_sell: "buy", currency: "GBP", security_exchange: "L"}

// For using apply inside setTimeout calls when wanting to remove a loading cursor
const removeLoadingClass = node => {
    node.classList.remove("wait")
}

const customAlgorithms = [
    {
        name: "None",
        value: "none"
    },
    {
        name: "In-N-Out",
        value: "in-n-out"
    }
]

const orderTypes = [
    {
        name: "DAY",
        value: "day_order"
    },
    {
        name: "IOC",
        value: "immediate_or_cancel"
    },
    {
        name: "GTC",
        value: "good_till_cancel"
    },
    {
        name: "FOK",
        value: "fill_or_kill"
    },
    {
        name: "GTD",
        value: "good_till_date"
    }
]

const standard_notification = {
    insert: "bottom",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["fadeOut"],
    dismiss: {
      duration: 5000,
      pauseOnHover: true,
      showIcon: true
    }
  }

const default_settings = {
    view: {account_overview: true, active_orders: true, trade_overview: true, dev_tools: false, 
    observation_lists: {
        main: true,
        hedging: false
    }},
    columns:{
        trading:{symbol: true, name: false, last_price: true, bid_size: true, bid_price: true, ask_price: true, ask_size: true,price_sync: true, custom_algorithms: false, time_in_force: false},
        active_orders: {price: true, quantity: true, updated: true, time_in_force: true, custom_algorithms:true, iceberg_shown: true}
    },
    orders: {
        hide_agg: false
    },
    text_size: 10
}

const tickSize = (tick_levels, top_of_book, buy_sell) => {
    if(tick_levels === undefined | tick_levels === null) return 1
    if(top_of_book !== undefined){
      let bid_ask = buy_sell === "buy" ? "bid_price":"ask_price" 
      if(top_of_book?.[bid_ask] > tick_levels[0].upper) return tick_levels[0].tick_size  
    }
    return tick_levels.at(-1).tick_size
}

const increaseAndRoundToNearest = (current, delta_step, default_delta, has_decimals) => {
    var parseNumber = has_decimals ? parseFloat:parseInt
    var step = parseNumber(delta_step ?? default_delta)
    var total = parseNumber(current ?? 0) + step
    total = delta_step ? Math.floor(total / step)*step:total
    return total + (total == current ? step:0)
}

const decreaseAndRoundToNearest = (current, delta_step, default_delta, has_decimals) => {
    var parseNumber = has_decimals ? parseFloat:parseInt
    let step = parseNumber(delta_step ?? default_delta)
    var total = parseNumber(current ?? 0) - step
    total = delta_step ? Math.ceil(total / step)*step:total
    return total - (total == current ? step:0)
}

const tricap_token_key = "_tricap_token_"

const switch_default = {uncheckedIcon:false, checkedIcon: false, height: 20, width: 42}

export {asNumber, cap, deepPath, formatFIXtime,deepUpdate, shortTimeFormat, removeLoadingClass, tickSize,
    increaseAndRoundToNearest, decreaseAndRoundToNearest, shortDateFormat, 
    default_instrument_params, customAlgorithms, orderTypes, standard_notification, default_settings, tricap_token_key, switch_default};
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import '../App.css';
import Context from '../store/context';
import api from '../api/api';
import betaRegressionApi from '../api/beta-regression-api';
import portfolioAnalysisApi from '../api/portfolio-analysis-api';
import { styles } from '../global/styles';
import Plot from 'react-plotly.js';
import { Select, Input } from 'antd';
import { sum } from 'lodash';
import LoadingSpinner from "../components/loading-spinner";
import Password from 'antd/es/input/Password';

const { Option } = Select;

const getRowColor = () => {
    let lastValue = null;
    let lastColor = null;
    return (value) => {
        if (value === lastValue) {
            return lastColor; // same color as last row
        } else {
            const newColor = lastColor === "#e2f0d9" ? null : "#e2f0d9"; // alternate color
            lastValue = value;
            lastColor = newColor;
            return newColor;
        }
    };
};

const Analysis = () => {
    const { user, setUser, globalState} = useContext(Context);
    const [resultsSettings, setResultsSettings] = useState({});
    const [results, setResults] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hedgeDataLoaded, setHedgeDataLoaded] = useState(false);
    const [visualizationTitle, setVisualizationTitle] = useState("SMT");
    const [funds, setFunds] = useState([]);
    const [visualizationType, setVisualizationType] = useState();
    const [page, setPage] = useState("single_regression");
    const [curHedgingInstrument, setCurHedgingInstrument] = useState([]);
    const [hedgingInstruments, setHedgingInstruments] = useState([]);
    const [hedgingInstrumentsDataType, setHedgingInstrumentsDataType] = useState("nav");
    const [betaRegression, setBetaRegression] = useState({});
    const [portfolioAnalysisData, setPortfolioAnalysisData] = useState({});
    const [betaRegressionSettings, setBetaRegressionSettings] = useState({
        fund: "SMT", start_date: new Date(new Date().setDate(new Date().getDate()-90)).toISOString().slice(0, 10),
        end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10),
    });
    const [curPortfolioAnalysisFunds, setCurPortfolioAnalysisFunds] = useState([])
    const [portfolioAnalysisSettings, setPortfolioAnalysisSettings] = useState({
        funds: ["PCT", "ATT"], start_date: new Date(new Date().setDate(new Date().getDate()-90)).toISOString().slice(0, 10),
        end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10),
        nav_type: "official"
    });
    const getRowColorFunc = getRowColor();
      
    const dateDiff = (date1, date2) => {
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        return diffDays;
    };

    useEffect(() => {
        async function getResults() {
            if (resultsSettings?.fund) {
                await api(`fund-data/get-beta-regression-results?fund=${resultsSettings?.fund}`).then(res => res.json()).then(data => {setResults(data)});
            } else if (resultsSettings?.date) {
                await api(`fund-data/get-beta-regression-results?date=${resultsSettings?.date}`).then(res => res.json()).then(data => {setResults(data)});
            } else {
                await api("fund-data/get-beta-regression-results").then(res => res.json()).then(data => {setResults(data)});
            }
        }
        if (page == 'results') {
            getResults()
        }
    }, [page, resultsSettings])

    useEffect(() => {
        async function getCurHedge() {
            await api(`fund-data/current-hedge?ticker=${betaRegressionSettings?.fund}`).then(res => res.json()).then(data => {
                setBetaRegressionSettings({...betaRegressionSettings, 
                    hedging_instruments: data?.data?.hedging_instruments, 
                    hedging_ratios: data?.data?.hedging_ratios, 
                    beta_actual: data?.data?.beta_exposure,
                    lower_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 0.0),
                    upper_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 1.0),
                });
                setHedgeDataLoaded(true);
            });    
        }
        getCurHedge()
    }, [])

    useEffect(() => {
        async function getFunds() {
            await api("fund-data/all-funds").then(res => res.json()).then(data => {
                setFunds(data?.data);
            });
        }
        getFunds()
    }, [])

    useEffect(() => {
        async function getHedgingInstruments() {
            await api("hedging-instruments").then(res => res.json()).then(data => {
                setHedgingInstruments(data?.data);
            });    
        }
        getHedgingInstruments()
    }, [])

    useEffect(() => {
        if (page !== "portfolio_analysis") {
            setPortfolioAnalysisSettings({
                funds: ["PCT", "ATT"], start_date: new Date(new Date().setDate(new Date().getDate()-90)).toISOString().slice(0, 10),
                end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10),
                nav_type: "official"
            })
        }
    }, [page])

    useEffect(() => {
        setIsLoading(true)
        async function getBetaRegression() {
            setBetaRegression({});
            await betaRegressionApi("regression", "POST", {
                fund: betaRegressionSettings?.fund, 
                hedging_instruments: betaRegressionSettings?.hedging_instruments?.map(str => str.toLowerCase()),
                hedging_ratios: betaRegressionSettings?.hedging_ratios,
                start_date: betaRegressionSettings?.start_date,
                end_date: betaRegressionSettings?.end_date,
                type: hedgingInstrumentsDataType
            })
            .then(response => response.json())
            .then(data => {
                setBetaRegression(data);
                setIsLoading(false)
            });
        }
        if (hedgeDataLoaded) {
            getBetaRegression();
        }
      }, [hedgeDataLoaded]);


    useEffect(() => {
        async function getPortfolioAnalysis() {
            await portfolioAnalysisApi("analysis", "POST", {
                funds: portfolioAnalysisSettings?.funds, 
                start_date: portfolioAnalysisSettings?.start_date,
                end_date: portfolioAnalysisSettings?.end_date,
                nav_type: portfolioAnalysisSettings?.nav_type
            })
            .then(response => response.json())
            .then(data => {
                setPortfolioAnalysisData(data);
                setIsLoading(false)
            });
        }
        if (page === "portfolio_analysis") {
            setIsLoading(true)
            getPortfolioAnalysis();
            setCurPortfolioAnalysisFunds(portfolioAnalysisSettings?.funds)
        }
    }, [page]);

    // Dynamically creates input boxes.
    const inputBoxes = useMemo(() => {
    return betaRegressionSettings?.hedging_instruments?.map((instrument, i) => {
        return (
        <div style={{paddingBottom: 20}} key={i}>
            <div style={{ padding: 5 }}>
            <label>{instrument}:</label>
            <Input style={{maxWidth:300}}
                type="number"
                step="0.01"
                value={betaRegressionSettings?.hedging_ratios[i] || 0}
                onChange={(e) => {
                const inputVal = e.target.value.trim();
                    const hedgingRatiosCopy = [
                    ...betaRegressionSettings.hedging_ratios,
                    ];
                    hedgingRatiosCopy[i] = parseFloat(inputVal) || 0;
                    setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    hedging_ratios: hedgingRatiosCopy,
                    });
                }}
            />
            <button className='hover-update'
                style={{ marginLeft: 5, cursor: 'pointer' }}
                onClick={() => {
                const hedgingInstrumentsCopy = [...betaRegressionSettings.hedging_instruments];
                const hedgingRatiosCopy = [...betaRegressionSettings.hedging_ratios];
                const lowerBoundsCopy = [...betaRegressionSettings.lower_bounds];
                const upperBoundsCopy = [...betaRegressionSettings.upper_bounds];
                hedgingInstrumentsCopy.splice(i, 1);
                hedgingRatiosCopy.splice(i, 1);
                lowerBoundsCopy.splice(i, 1);
                upperBoundsCopy.splice(i, 1);
                setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    hedging_instruments: hedgingInstrumentsCopy,
                    hedging_ratios: hedgingRatiosCopy,
                    lower_bounds: lowerBoundsCopy,
                    upper_bounds: upperBoundsCopy,
                });
                }}
            >
                x
            </button>
            </div>
            <div style={{ padding: 5 }}>
            <label>Lower Bound:</label><br/>
            <Input style={{maxWidth:100}}
                type="number"
                step="0.01"
                value={betaRegressionSettings?.lower_bounds?.[i] || 0.0}
                onChange={(e) => {
                    const inputVal = e.target.value.trim();
                    const lowerBoundsCopy = [
                    ...betaRegressionSettings?.lower_bounds,
                    ];
                    lowerBoundsCopy[i] = parseFloat(inputVal) || 0.0;
                    setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    lower_bounds: lowerBoundsCopy,
                    });
                }}
            /><br/>
            <label>Upper Bound:</label><br/>
            <Input style={{maxWidth:100}}
                type="number"
                step="0.01"
                value={betaRegressionSettings?.upper_bounds?.[i] || 0.0}
                onChange={(e) => {
                    const inputVal = e.target.value.trim();
                    const upperBoundsCopy = [
                    ...betaRegressionSettings?.upper_bounds,
                    ];
                    upperBoundsCopy[i] = parseFloat(inputVal) || 0.0;
                    setBetaRegressionSettings({
                    ...betaRegressionSettings,
                    upper_bounds: upperBoundsCopy,
                    });
                }}
            /><br/>
            </div>
        </div>
        );
    });
    }, [betaRegressionSettings]);
      
    if (page === 'results') {
        return (
            <main style={{height: "100%", boxSizing: "inherit"}}>
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <table border={1} style={{...classes.table, fontSize: "0.8rem"}}>
                        {results?.headers?.map((row, i) => (
                            <thead key={i} style={classes.header}>
                                <tr key={row?.[0]?.name}>
                                {row.map((header, h) => {
                                    var thClass = header?.class ?? ""
                                    if(i === 0) thClass += "sep-right"
                                    return (
                                    <th key={header?.name + h} className={thClass} colSpan={header?.colspan ?? 1}>{header?.name}</th>
                                )})}
                                </tr>
                            </thead>
                        ))}
                        <tbody>
                            {results?.rows?.map((row, i) => (
                                 <tr className={row?.class} style={{textAlign: "center", backgroundColor: getRowColorFunc(row.values[row.values.length - 1].v)}} key={i}>
                                    {row?.values?.map((val, i) => (<td key={val?.v + i} className={val?.class}>{val?.v}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{justifyContent: "space-around", minWidth: 300, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50, border:"1px solid white"}}>
                    <h3 style={{paddingBottom:5}}>Pages:</h3>
                    <Select
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a page"
                        optionFilterProp="page"
                        defaultValue="single_regression"
                        value={page}
                        onChange={(value) => {
                            setPage(value)
                        }}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                            <Option value="single_regression">Single Regression</Option>
                            <Option value="results">Regression Results</Option>
                            <Option value="portfolio_analysis">Portfolio Correlation Analysis</Option>
                    </Select>
                    <h3 style={{paddingBottom:5}}>Funds:</h3>
                    <Select
                        showSearch
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a fund"
                        optionFilterProp="fund"
                        value={resultsSettings?.fund}
                        onChange={value => setResultsSettings({...resultsSettings, fund: value, date: "", r_squared_filter: "0.6"})}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {funds.map((fundOption) => (
                            <Option value={fundOption}>{fundOption}</Option>
                        ))}
                    </Select>
                    <h3 style={{paddingBottom:5}}>Date:</h3>
                    <input value={resultsSettings?.date} style={{...styles.input, padding: 5, fontSize: 12, maxWidth: 200}} type="date" onChange={e => setResultsSettings({...resultsSettings, date: e?.target?.value, fund: null, r_squared_filter: "0.6"})}/>
                    <p style={{paddingBottom:5, paddingTop:15}}>R-Squared Filter (%):</p>
                    <Input defaultValue="0.6" type="text" value={resultsSettings?.r_squared_filter} onChange={e => {
                        if (/^\d*\.?\d*$/.test(e?.target?.value)) {
                            setResultsSettings({...resultsSettings, r_squared_filter: e?.target?.value, fund: null, date: ""});
                        } else {
                            alert('Please enter a valid float number');
                        }
                    }} /><br/>
                    <div>
                        <button className='hover-update' onClick={() => {
                            async function getBetaRegression() {
                                await api(`fund-data/get-beta-regression-results?r_squared_filter=${resultsSettings?.r_squared_filter}`).then(res => res.json()).then(data => {setResults(data)});
                            }
                            getBetaRegression()
                        }}>Update R-Squared Filter</button>
                    </div>
                    <div style={{paddingTop:40}}>
                    <button className='hover-update' onClick={() => {
                        async function getBetaRegression() {
                            setResultsSettings({...resultsSettings, fund: null, date: "", r_squared_filter: "0.6"})
                            await api(`fund-data/get-beta-regression-results`).then(res => res.json()).then(data => {setResults(data)});
                        }
                        getBetaRegression()
                    }}>Reset Settings</button>
                    </div>
                </div>
            </main>
        )
    } else if (page === 'single_regression') {
        return (
            <main style={{height: "100%", boxSizing: "inherit"}}>
                {isLoading && (
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                        <button style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                        setIsLoading(false);
                        }}>Cancel</button>
                    </div>)}
                <div style={{justifyContent: "left", float: "left", display: "flex", paddingTop: 10, flex: 10}}>
                    <div style={{borderRadius: 25, padding: 10, backgroundColor: "#fff"}}>
                    {visualizationType === 'return' ? (
                        <Plot
                            data={[
                                {
                                    x: betaRegression?.dates,
                                    y: betaRegression?.nav_pct_return,
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'NAV Total Return',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                },
                                {
                                    x: betaRegression?.dates, 
                                    y: betaRegression?.hedge_pct_return,
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'Hedge Total Return',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                }
                            ]}
                            layout={{
                                width: 1200,
                                height: 800,
                                title: `${visualizationTitle}`, 
                                xaxis: {
                                    title: "Date",
                                }, 
                                yaxis: {
                                    title: "Percentage Return"
                                },
                                legend: {
                                    orientation: "h", 
                                    y: 1.1,
                                },
                            }}
                        />
                    ) : visualizationType === 'beta_adj_return' ? (
                        <Plot
                            data={[
                                {
                                    x: betaRegression?.dates,
                                    y: betaRegression?.nav_pct_return,
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'NAV Total Return',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                },
                                {
                                    x: betaRegression?.dates, 
                                    y: betaRegression?.hedge_pct_return?.map(num => num * betaRegression?.beta),
                                    type: 'scatter',
                                    mode: 'lines',
                                    name: 'Hedge Total Return (Beta Adjusted)',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                }
                            ]}
                            layout={{
                                width: 1200,
                                height: 800,
                                title: `${visualizationTitle}`, 
                                xaxis: {
                                    title: "Date",
                                }, 
                                yaxis: {
                                    title: "Percentage Return"
                                },
                                legend: {
                                    orientation: "h", 
                                    y: 1.1,
                                },
                            }}
                        />
                    ) : (
                        <Plot
                            data={[{
                                    x: betaRegression?.hedge_pct_return,
                                    y: betaRegression?.nav_pct_return, 
                                    type: 'scatter', 
                                    mode: 'markers',
                                    name: 'Beta',
                                    hoverinfo: "text+skip",
                                    line: {width: 0.5}
                                },
                                {
                                    x: betaRegression?.hedge_pct_return, 
                                    y: betaRegression?.pred, 
                                    type: 'scatter', 
                                    mode: 'lines', 
                                    name: 'Regression Line', 
                                    hoverinfo: "text+skip",
                                    line: {width: 2}
                                }
                            ]}
                            layout={{
                                width: 1200,
                                height: 800, 
                                title: `${visualizationTitle}`,
                                xaxis: {
                                    title: "Hedge Percentage Return",
                                }, 
                                yaxis: {
                                    title: "NAV Percentage Return"
                                },
                                legend: {
                                    orientation: "h", 
                                    y: 1.1,
                                },
                            }}
                        />
                    )}
                </div>
                </div>
                <div style={{justifyContent: "space-around", minWidth: 300, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50, border:"1px solid white"}}>
                <h3 style={{paddingBottom:5}}>Pages:</h3>
                <Select
                    style={{ width: 200, paddingBottom:15}}
                    placeholder="Select a page"
                    optionFilterProp="page"
                    defaultValue="single_regression"
                    value={page}
                    onChange={(value) => {
                        setPage(value)
                    }}
                    filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                        <Option value="single_regression">Single Regression</Option>
                        <Option value="results">Regression Results</Option>
                        <Option value="portfolio_analysis">Portfolio Correlation Analysis</Option>
                </Select>
                </div>
                <div style={{justifyContent: "space-around", minWidth: 300, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50, border:"1px solid white"}}>
                <h3 style={{paddingBottom:5}}>Visualization:</h3>
                <Select
                    style={{ width: 200, paddingBottom:15}}
                    placeholder="Select a visualization"
                    optionFilterProp="visualization"
                    defaultValue="regression"
                    value={visualizationType}
                    onChange={(value) => {
                        setVisualizationType(value)
                    }}
                    filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                        <Option value="regression">Regression</Option>
                        <Option value="return">% Return</Option>
                        <Option value="beta_adj_return">% Return (Beta Adjusted)</Option>
                </Select>
                </div>
                <div style={{justifyContent: "space-around", minWidth:375, maxWidth:375, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50, paddingBottom:10, border:"1px solid white"}}>
                <h3 style={{paddingBottom:5}}>Funds:</h3>
                    <Select
                        showSearch
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a fund"
                        optionFilterProp="fund"
                        value={betaRegressionSettings?.fund}
                        onChange={value => setBetaRegressionSettings({...betaRegressionSettings, fund: value})}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {funds.map((fundOption) => (
                            <Option key={fundOption} value={fundOption}>{fundOption}</Option>
                        ))}
                    </Select>
                    <h3 style={{paddingBottom:5, paddingTop: 30}}>Hedging Instruments:</h3>
                    <Select
                        showSearch
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a hedging instrument"
                        optionFilterProp="hedging_instruments"
                        value={curHedgingInstrument}
                        onChange={value => setCurHedgingInstrument(value)}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {hedgingInstruments.map((hedgingInstrumentOption) => (
                            <Option value={hedgingInstrumentOption}>{hedgingInstrumentOption}</Option>
                        ))}
                    </Select>
                    <div style={{padding: 2}}>
                        <button className='hover-update' style={{minWidth:75}} onClick={() => {
                            if (betaRegressionSettings?.hedging_instruments.includes(curHedgingInstrument)) {
                                alert('Hedging instrument already added.');
                            } else {
                                setBetaRegressionSettings({...betaRegressionSettings, 
                                    hedging_instruments: [...betaRegressionSettings.hedging_instruments, curHedgingInstrument],
                                    lower_bounds: [...betaRegressionSettings?.lower_bounds, 0.0],
                                    upper_bounds: [...betaRegressionSettings?.upper_bounds, 1.0],
                                })
                            }
                        }}>Add</button>
                    </div>
                    <h3 style={{paddingBottom:5, paddingTop: 30}}>Hedging Instruments Datatype:</h3>
                    <Select
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select datatype"
                        optionFilterProp="hedging_instruments_datatype"
                        value={hedgingInstrumentsDataType}
                        onChange={value => setHedgingInstrumentsDataType(value)}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        <Option value={"nav"}>NAV (Only for ETF)</Option>
                        <Option value={"tot_return_index_gross_dvds"}>Total Return Gross Dividends</Option>
                    </Select>
                    <h3 style={{paddingBottom:5, paddingTop: 30}}>Hedging Ratios:</h3>
                    {inputBoxes}
                    <div style={{paddingTop: 30}}>
                        <p style={{paddingBottom:5}}>Start Date:</p>
                        <input value={betaRegressionSettings?.start_date} style={{...styles.input, padding: 5, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                            setBetaRegressionSettings({...betaRegressionSettings, start_date: e?.target?.value})
                        }}/>
                        <p style={{paddingBottom:5}}>End Date:</p>
                        <input value={betaRegressionSettings?.end_date} style={{...styles.input, padding: 5, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                            setBetaRegressionSettings({...betaRegressionSettings, end_date: e?.target?.value})
                        }
                        }/>
                    </div>
                    <div style={{paddingTop:50}}>
                        <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                            async function getCurHedge() {
                                if (betaRegressionSettings?.fund === 'PNL') {
                                    alert("Unable to load current hedge for PNL. Hedging instruments not supported.")
                                } else {
                                    setIsLoading(true)
                                    await api(`fund-data/current-hedge?ticker=${betaRegressionSettings?.fund}`).then(res => res.json()).then(data => {
                                        setHedgeDataLoaded(false)
                                        setBetaRegressionSettings({...betaRegressionSettings, 
                                            hedging_instruments: data?.data?.hedging_instruments, 
                                            hedging_ratios: data?.data?.hedging_ratios, 
                                            beta_actual: data?.data?.beta_exposure,
                                            lower_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 0.0),
                                            upper_bounds: data?.data?.hedging_instruments?.map((instrument, i) => 1.0),
                                            beta_actual: data?.data?.beta_exposure,
                                        });
                                        setHedgeDataLoaded(true)
                                        setIsLoading(false)
                                    });
                                }  
                            }
                            getCurHedge()
                            setVisualizationTitle(betaRegressionSettings?.fund)
                        }}>Reset Settings</button>
                    </div>
                    <div style={{paddingTop: 10}}>
                    <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                        if (sum(betaRegressionSettings?.hedging_ratios) > 1.01 | sum(betaRegressionSettings?.hedging_ratios < 0.0)) {
                            alert('The hedging ratios should sum to 1. Please input valid hedging ratios.');
                        } else {
                            setIsLoading(true);
                            async function getBetaRegression() {
                                setBetaRegression({});
                                await betaRegressionApi("regression", "POST", {
                                  fund: betaRegressionSettings?.fund, 
                                  hedging_instruments: betaRegressionSettings?.hedging_instruments.map(str => str.toLowerCase()),
                                  hedging_ratios: betaRegressionSettings?.hedging_ratios,
                                  start_date: betaRegressionSettings?.start_date,
                                  end_date: betaRegressionSettings?.end_date,
                                  type: hedgingInstrumentsDataType
                                })
                                  .then(response => response.json())
                                  .then(data => {
                                    setBetaRegression(data);
                                    setIsLoading(false)
                                  });
                              }
                            getBetaRegression()
                            setVisualizationTitle(betaRegressionSettings?.fund)
                        }
                    }}>Run Beta Regression</button>
                    </div>
                    <div style={{paddingTop:10}}>
                        <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                            if (dateDiff(new Date(betaRegressionSettings?.start_date), new Date(betaRegressionSettings?.end_date)) > 90) {
                                alert("Optimization only allowed for maximum 90 days. Please run optimization on shorter period.")
                            } else if (betaRegressionSettings?.hedging_instruments?.length > 3) {
                                alert("Optimization only allowed for 3 hedging instruments at a time. Please run optimization on fewer instruments.")
                            } else {
                                setIsLoading(true)
                                async function getOptimizationResults() {
                                    await betaRegressionApi("optimize", "POST", {
                                        fund: betaRegressionSettings?.fund, 
                                        hedging_instruments: betaRegressionSettings?.hedging_instruments?.map(str => str.toLowerCase()),
                                        start_date: betaRegressionSettings?.start_date,
                                        end_date: betaRegressionSettings?.end_date,
                                        bounds: betaRegressionSettings?.lower_bounds?.map((lower_bound, i) => [lower_bound, betaRegressionSettings?.upper_bounds[i]]),
                                        type: hedgingInstrumentsDataType
                                    })
                                    .then(response => response.json())
                                    .then(data => {
                                        setHedgeDataLoaded(false)
                                        setBetaRegressionSettings({...betaRegressionSettings, hedging_ratios: data?.hedging_ratios});
                                        setHedgeDataLoaded(true)
                                        setIsLoading(false)
                                    });
                                }
                                getOptimizationResults()
                            }
                        }}>Optimize Hedging Instrument Mix</button>
                    </div>
                    <div style={{paddingTop:10}}>
                        <button className='hover-update' style={{padding: 10, minWidth: 260}} onClick={() => {
                            async function postBetaRegressionResults() {
                                await api(`fund-data/beta-regression-results`, "POST", {
                                    "fund": [betaRegressionSettings?.fund],
                                    "hedging_instruments": betaRegressionSettings?.hedging_instruments,
                                    "hedging_ratios": betaRegressionSettings?.hedging_ratios?.map(hedging_ratio => hedging_ratio.toString()),
                                    "beta": [betaRegression?.beta.toString()],
                                    "r_squared": [betaRegression?.r_squared.toString()],
                                    "start_date": [betaRegressionSettings?.start_date],
                                    "end_date": [betaRegressionSettings?.end_date],
                                }).then(res => res.json()).then(data => {
                                    if (data?.status === 'success') {
                                        alert("Beta regression results successfully saved to database.")
                                    } else {
                                        alert("Unable to save beta regression results to database.")
                                    }
                                });
                            }
                            postBetaRegressionResults()
                        }}>Save Beta Regression Results</button>
                    </div>
                </div>
                <div style={{justifyContent: "space-around", margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"left", paddingBottom:20, border:"1px solid white"}}>
                {Object.keys(betaRegression).length !== 0 && (
                    <table border={1} style={{...styles.table}}>
                        <thead>
                            <tr>
                            <th>Beta (Analysis)</th>
                            <th>Beta (Actual)</th>
                            <th>R-Squared</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td style={{textAlign: "center", minWidth: 100}} key={"beta"}>{betaRegression?.beta?.toFixed(3)}</td>
                            <td style={{textAlign: "center", minWidth: 100}} key={"beta_actual"}>{betaRegressionSettings?.beta_actual?.toFixed(3)}</td>
                            <td style={{textAlign: "center", minWidth: 100}} key={"r_squared"}>{betaRegression?.r_squared?.toFixed(3)}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                </div>
            </main>
        );
    } else if (page === 'portfolio_analysis') {
        return (
            <main style={{height: "100%", boxSizing: "inherit"}}>
                {isLoading && (
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <LoadingSpinner style={{ margin: 0, padding: 0 }}/>
                        <button style={{padding: 15, marginTop: 0, width: "5%"}} onClick={() => {
                        setIsLoading(false);
                        }}>Cancel</button>
                    </div>)}
                {/* <div style={{justifyContent: "left", float: "left", display: "flex", paddingTop: 10, flex: 10}}> */}
                <div style={{justifyContent: "center", display: "flex", paddingTop: 10, paddingLeft: 20, flex: 10, float:"left"}}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h3>Portfolio Securities Intersection Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.intersection_matrix?.[i]?.[j]}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3>Portfolio Securities % NAV Intersection Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.intersection_percentage_nav_matrix?.[i]?.[j]?.toFixed(2)}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3>NAV Correlation Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.nav_correlation_matrix?.[i]?.[j]?.toFixed(2)}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <h3>Prem/Disc Correlation Matrix:</h3>
                        <table border={1} style={{...classes.table, fontSize: "0.8rem", marginBottom: 25}}>
                            <thead style={classes.header}>
                                <tr>
                                <th></th>
                                {curPortfolioAnalysisFunds?.map((fund) => {
                                    return (
                                    <th key={fund}>{fund}</th>
                                )})}
                                </tr>
                            </thead>
                            <tbody>
                                {curPortfolioAnalysisFunds?.map((fund, i) => {
                                    return (
                                        <tr key={fund}>
                                            <th style={classes.header}>{fund}</th>
                                            {curPortfolioAnalysisFunds?.map((fund, j) => (
                                                <th>{portfolioAnalysisData?.prem_disc_correlation_matrix?.[i]?.[j]?.toFixed(2)}</th>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{justifyContent: "space-around", minWidth: 300, margin: 10, display: "flex", flex: 2, flexDirection: "column", float:"right", paddingRight:50, border:"1px solid white"}}>
                    <h3 style={{paddingBottom:5}}>Pages:</h3>
                    <Select
                        style={{ width: 200, paddingBottom:15}}
                        placeholder="Select a page"
                        optionFilterProp="page"
                        defaultValue="single_regression"
                        value={page}
                        onChange={(value) => {
                            setPage(value)
                        }}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                            <Option value="single_regression">Single Regression</Option>
                            <Option value="results">Regression Results</Option>
                            <Option value="portfolio_analysis">Portfolio Correlation Analysis</Option>
                    </Select>
                    <p>Funds:</p>
                    <select value={portfolioAnalysisSettings?.funds?.length == 0 ? ["PCT", "ATT"]:portfolioAnalysisSettings?.funds} multiple
                    style={{width: 400, height: 400, padding: 5, alignSelf: "flex-end"}}
                    onChange={e => {
                        e.persist();
                        var funds = []
                        for(let i = 0; i < e.target.options.length; i++) if(e.target.options[i].selected) funds.push(e.target.options[i].value)
                        if(funds.length > 0) setPortfolioAnalysisSettings({...portfolioAnalysisSettings, funds:funds})
                        }}>
                        {funds && funds?.map(fund => (<option selected>{fund}</option>))}
                    </select>
                    <div style={{paddingTop: 30}}>
                        <p style={{paddingBottom:5}}>Start Date:</p>
                        <input value={portfolioAnalysisSettings?.start_date} style={{...styles.input, padding: 5, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                            setPortfolioAnalysisSettings({...portfolioAnalysisSettings, start_date: e?.target?.value})
                        }}/>
                        <p style={{paddingBottom:5}}>End Date:</p>
                        <input value={portfolioAnalysisSettings?.end_date} style={{...styles.input, padding: 5, fontSize: 12, maxWidth: 200}} type="date" onChange={e => {
                            setPortfolioAnalysisSettings({...portfolioAnalysisSettings, end_date: e?.target?.value})
                        }
                        }/>
                    </div>
                    <p style={{paddingTop: 30}}>NAV Type:</p>
                    <Select
                        style={{ width: 200, paddingBottom:15, paddingTop: 5}}
                        placeholder="Select NAV Type"
                        optionFilterProp="nav_type"
                        defaultValue="official"
                        value={portfolioAnalysisSettings?.nav_type}
                        onChange={(value) => {
                            setPortfolioAnalysisSettings({...portfolioAnalysisSettings, nav_type: value})
                        }}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                            <Option value="official">Official NAV</Option>
                            <Option value="uk_close">UK Close NAV</Option>
                    </Select>
                    <div style={{paddingTop:10}}>
                        <button className='hover-update' style={{padding: 10, minWidth: 150, marginBottom: 10}} onClick={() => {
                            async function getPortfolioAnalysis() {
                                await portfolioAnalysisApi("analysis", "POST", {
                                    funds: portfolioAnalysisSettings?.funds, 
                                    start_date: portfolioAnalysisSettings?.start_date,
                                    end_date: portfolioAnalysisSettings?.end_date,
                                    nav_type: portfolioAnalysisSettings?.nav_type
                                })
                                .then(response => response.json())
                                .then(data => {
                                    setPortfolioAnalysisData(data);
                                    setIsLoading(false)
                                });
                            }
                            if (portfolioAnalysisSettings?.funds.length > 1) {
                                setIsLoading(true)
                                getPortfolioAnalysis()
                                setCurPortfolioAnalysisFunds(portfolioAnalysisSettings?.funds)
                            } else {
                                alert('Please select more than one fund to run analysis.')
                            }
                        }}>Run Analysis</button>
                    </div>
                </div>
            </main>
        )
    }
}

const classes = {
    box: {
        backgroundColor: "white",
        position:"fixed",
        padding: 10,
        borderRadius: 25,
        zIndex: 999,
    },
    closing_icon: {
        position: "absolute", 
        right:12, 
        top: 12,
        cursor:"pointer",
    },
    table: {
        borderSpacing: 0,
        backgroundColor: "white",
        color: "rgba(1,1,1,1.0)",
        minWidth: 800
    },
    header: {
        backgroundColor: "rgb(32,56,100)",
        color: "white",
        padding: "3px 5px",
        textAlign: "center",
        borderBottom: "3px solid black",
    },
    cell: {
        backgroundColor: "white",
        padding: 5,
    }
}

export default Analysis;